import { toaster } from "@phonero/pux-react"

import { AppRoute, hasCostTransferAccess } from "@phonero/common-ux"

/** A mapping of all routes within the application */

export type RouteKeys = keyof typeof routes

/**
 * About these routes:
 *
 * These routes are use both by the application-router, and for typesafe navigation.
 *
 * To not have to duplicate the path-paramater for these usages, the path-key is a function.
 * Passing null/undefined as parameters is intended for the router, while other values should be used while navigating
 *
 * IMPORTANT: Order matters
 *
 * The router should have the most precise routes at the top, while the most general at the bottom.
 * This is to work better with the routers Switch-component, without needing to use `exact`
 */

//const subscriptionPath = ":subscriptionId"
/** A mapping of all routes within the application */
export const routes = {
  /** Payment-callback for vipps.
   This page is only used when a user has paid for an item with Vipps.

   The payment is either completed successfully, or failed.
   */

  paymentCallbackVipps: {
    path: "/paymentCallback/Vipps/:paymentID",
    name: "Routes.PaymentCallback",
    noBackLink: true,
    isPublic: true,
  } as AppRoute,
  playground: {
    path: "/playground",
    name: "Playground",
    isPublic: true,
    restrictions: (isDev: boolean) => (isDev ? true : "Finner ikke siden"),
  } as AppRoute,
  /** Contact-page, showing contact information for customer-support etc. */
  contact: {
    path: "/(contact|kontakt)",
    name: "Routes.Contact",
    isPublic: true,
  } as AppRoute,
  /** Welcome-page */
  welcome: {
    path: `/(welcome|velkommen)`,
    name: "Routes.Welcome",
    isPublic: true,
    noBackLink: true,
  } as AppRoute,
  /** Subscription-page (Abonnement) */
  subscription: {
    path: `/(subscription|abonnement)`,
    name: "Routes.Subscription",
  } as AppRoute,
  /** Subscription-Primary Sim Card */
  subscriptionPrimarySimCardPage: {
    path: `/(subscription|abonnement)/(primarysim|hovedsim)`,
    name: "Routes.PrimarySim",
    isPublic: true,
  } as AppRoute,
  /** Subscription-Data Sim Card */
  subscriptionDataSimCardPage: {
    path: `/(subscription|abonnement)/datasim`,
    name: "Routes.Datasim",
    isPublic: true,
  } as AppRoute,
  /** Subscription-Twin Sim Cards */
  subscriptionTwinSimCardPage: {
    path: `/(subscription|abonnement)/(twinsim|tvillingsim)`,
    name: "Routes.TwinSim",
    isPublic: true,
  } as AppRoute,
  /** Subscription-SmartWatch Sim Cards */
  subscriptionSmartWatchSimCardPage: {
    path: `/(subscription|abonnement)/(smartwatch|smartklokke)`,
    name: "Routes.SmartWatchSim",
    isPublic: true,
  } as AppRoute,
  /** Services (Tjenester) */
  subscriptionServices: {
    path: `/(services|tjenester)`,
    name: "Routes.SubscriptionServices",
  } as AppRoute,
  /** Services (Innstillinger) */
  settings: {
    path: `/(manage|administrer)`,
    name: "Routes.Settings",
  } as AppRoute,
  /** Usage (Forbruk) */
  subscriptionUsage: {
    path: `/(usage|forbruk)/:period?`,
    name: "Routes.SubscriptionUsage",
  } as AppRoute,
  /** Usage report (Innrapportering) */
  costTransferPage: {
    path: `/(usage|forbruk)/innrapportering`,
    name: "Routes.CostTransferPage",
    restrictions: () => {
      const ok = hasCostTransferAccess()
      switch (ok) {
        case undefined:
          return "Venter..."
        case "loading":
          return "Laster rettigheter..."
        case true:
          return true
        case false:
      }

      toaster.error(ok as any)
      return { redirect: "/forbruk" }
    },
  } as AppRoute,
  /** Arkivdetaljer (Innrapportering) */
  costTransferArchiveMonthPage: {
    path: `/(usage|forbruk)/innrapportering/arkiv/:period`,
    name: "Arkiv",
    restrictions: () => {
      const ok = hasCostTransferAccess()
      switch (ok) {
        case undefined:
          return "Venter..."
        case "loading":
          return "Laster rettigheter..."
        case true:
          return true
        case false:
      }
      toaster.error(ok as any)
      return { redirect: "/forbruk" }
    },
  } as AppRoute,
  /** Arkivdetaljer (Innrapportering) */
  costTransferArchive: {
    path: `/(usage|forbruk)/innrapportering/(arkiv)/`,
    name: "Arkiv",
    restrictions: () => {
      const ok = hasCostTransferAccess()
      switch (ok) {
        case undefined:
          return "Venter..."
        case "loading":
          return "Laster rettigheter..."
        case true:
          return true
        case false:
      }
      toaster.error(ok as any)
      return { redirect: "/forbruk" }
    },
  } as AppRoute,
  /** Kostnadsdetaljer arkiv (Innrapportering) */
  costTransferArchiveMonthDetailPage: {
    path: `/(usage|forbruk)/innrapportering/arkiv/:period?/detail/:category`,
    name: "Arkiv",
    restrictions: () => {
      const ok = hasCostTransferAccess()
      switch (ok) {
        case undefined:
          return "Venter..."
        case "loading":
          return "Laster rettigheter..."
        case true:
          return true
        case false:
      }
      toaster.error(ok as any)
      return { redirect: "/forbruk" }
    },
  } as AppRoute,
  /** Kostnadsdetaljer arkiv (Innrapportering) */
  costTransferPeriodMonthDetailPage: {
    path: `/(usage|forbruk)/innrapportering/:period?/:category`,
    name: "Denne perioden",
    restrictions: () => {
      const ok = hasCostTransferAccess()
      switch (ok) {
        case undefined:
          return "Venter..."
        case "loading":
          return "Laster rettigheter..."
        case true:
          return true
        case false:
      }
      toaster.error(ok as any)
      return { redirect: "/forbruk" }
    },
  } as AppRoute,
  /** Activity (Aktivitetslogg) */
  subscriptionActivity: {
    path: `/(activity|aktivitet)`,
    name: "Routes.SubscriptionActivity",
  } as AppRoute,
  /** Activity (Mine kjøp) */
  receipt: {
    path: `/(receipts|kvitteringer)/:orderId`,
    name: "Routes.Receipt",
  } as AppRoute,
  myReceipts: {
    path: `/(receipts|kvitteringer)`,
    name: "Routes.Receipts",
  } as AppRoute,
  /**Sidemenyinstillinger (Menu settings) */
  menuSettings: {
    path: `/(settings|innstillinger)`,
    name: "Routes.MenuSettings",
  } as AppRoute,
  myProfile: {
    path: `/(profile|profile)`,
    name: "Routes.MyProfile",
  } as AppRoute,
  /**Samtykkeinstillinger (Consent settings) */
  consentSettings: {
    path: `/(settings|innstillinger)/(consents|samtykker)`,
    name: "Routes.Consents",
  } as AppRoute,
  /** Requests (Forespørsler) */
  subscriptionRequests: {
    path: `/(requests|foresporsler|godkjenn)`,
    name: "Routes.SubscriptionRequests",
  } as AppRoute,
  /** Home-page */
  home: {
    path: `/`,
    name: "Routes.Home",
  } as AppRoute,
  /** 404 / No subscription
   * TODO: Differentaiate between a 404, and a Route where subscriptions do not match.
   * */
  noSubscriptionPage: {
    path: "",
    name: "404",
    isPublic: true,
  } as AppRoute,
}
