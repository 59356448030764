import { PuxButton, PuxText } from "@phonero/pux-react"
import css from "../Checkout.module.scss"
import classNames from "classnames"
import React, { FC } from "react"
import {
  CodewordType,
  DataBoostTheme,
  DataPackageTheme,
} from "@phonero/common-ux"

export const ConfirmContinue: FC<{
  title: string
  onClick: () => void
  onCancel: () => void
  theme?: DataPackageTheme | DataBoostTheme
  disabled?: boolean
}> = ({ title, onClick, onCancel, theme, disabled }) => {
  const boostTheme =
    theme && "band" in theme ? (theme as DataBoostTheme) : undefined
  const packageTheme =
    theme?.type === CodewordType.ExtraDataEEA
      ? (theme as DataPackageTheme)
      : undefined

  const color = packageTheme?.color ?? boostTheme?.color ?? "primary"
  return (
    <>
      {!!boostTheme && (
        <p>
          <PuxText
            style={{
              color: "var(--pux-color-primary)",
              fontSize: "1.9rem",
            }}
          >
            NB!
          </PuxText>{" "}
          <PuxText>Starter fra aktivering og kan kun brukes en gang.</PuxText>
        </p>
      )}
      <p>
        <PuxButton
          expand="block"
          className={css.button}
          color={color}
          size={"large"}
          onClick={onClick}
          disabled={disabled}
        >
          {title}
        </PuxButton>
      </p>
      <p>
        {!!boostTheme && (
          <PuxButton
            expand="block"
            color={"light"}
            className={classNames(css.button)}
            onClick={onCancel}
          >
            <p className={css.underline}> 
              Spar til senere
            </p>
          </PuxButton>
        )}
      </p>
    </>
  )
}

//style={{ textDecoration: "underline", cursor: "pointer" }}>