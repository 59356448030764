import React, { FC, lazy, useEffect, useRef, useState } from "react"
import {
  useKeycloak,
  useTokenData,
  useAppRouter,
  dataCy,
  useAppTranslations,
  useAppInfo,
  useBreakpointDesktop,
  useOutstandingPayments,
  ApprovalStatusFlag,
  AvatarInitials,
  useLogout,
} from "@phonero/common-ux"
import { createTypographyClasses } from "@phonero/pux-core"
import {
  PuxButton,
  PuxButtons,
  PuxIcon,
  PuxItem,
  PuxLabel,
  PuxList,
  PuxTypography,
} from "@phonero/pux-react"
import {
  activityLog,
  chat,
  close,
  externalLink,
  logOut,
  profile,
  request,
  shoppingCart,
  warning,
} from "@phonero/pux-icons/icons"
import css from "./RightMenu.module.scss"
import classNames from "classnames"
import { buildInfo, featureFlags, isDevEnv } from "../../appConstants"
import { menu as menuC, rightMenuR, useMenu } from "./menuController"
import { openLink } from "../../util/openLink"
import MyReceiptsUnreadBadge from "../MyReceiptsUnreadBadge"
import OrdersUnreadBadge from "../OrdersUnreadBadge"
import { BuildInfoButton } from "./Footer"
import ApprovalBadgeCount from "./MenuItems/ApprovalBadgeCount"
import { useApolloClient } from "@apollo/client"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { useAppRouterDP } from "../../util/useAppRouterDP"

const DevMenu = lazy(
  () => import(/* webpackChunkName: 'DevMenu' */ "../../DevMenu")
)

export const RightMenu: FC<any> = () => {
  const { keycloak } = useKeycloak()
  const { logout } = useLogout()
  const { goTo } = useAppRouterDP()
  const divRef = useRef<HTMLDivElement | null>(null)
  const token = useTokenData()
  const isDesktop = useBreakpointDesktop()
  const m = useMenu()
  const { t } = useAppTranslations()
  // These are only set in apps
  const approver: string[] | undefined =
    token["http://schemas.phonero.no/claims/approver"]
  const enabledMenuConsentItem = useFeatureIsOn("dpw-consent-menu-item")
  const chatbotFeatureFlag = useFeatureIsOn("phonelia-chatbot")

  const accountUrl = keycloak.createAccountUrl()
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key !== "Escape") {
        return
      }
      menuC.closeWithOffset()
    }
    document.addEventListener("keydown", onKeyDown)

    return () => {
      document.removeEventListener("keydown", onKeyDown)
    }
  }, [])

  const [updateBadgeCount, setUpdateBadgeCount] = useState(0)
  useEffect(() => {
    if (m.isOpen && !m.isClosing) {
      setUpdateBadgeCount((prev) => prev + 1)
    }
  }, [m.isOpen, m.isClosing])

  useEffect(() => {
    if (!m.isOpen) {
      return
    }
    if (!m.isOpening && !m.isClosing) {
      return
    }
  }, [m.isOpen, m.isClosing, m.isOpening])
  useEffect(() => {
    if (!divRef.current) {
      return
    }
    const t = (e: TransitionEvent) => {
      // We dont want to listen to pseudoElements etc
      if (e.target !== divRef.current) {
        return
      }

      const { isOpening, isClosing } = menuC.var()
      if (isOpening) {
        rightMenuR((s) => ({ ...s, isOpening: false, isOpen: new Date() }))
        return
      }
      if (isClosing) {
        rightMenuR((s) => ({ ...s, isClosing: false, isOpen: false }))
      }
    }
    const el = divRef.current
    el.addEventListener("transitionend", t)
    return () => {
      if (!el) {
        return
      }
      el.removeEventListener("transitionend", t)
    }
  }, [])
  const { purchases } = useOutstandingPayments()
  const firstPurchase = Object.values(purchases || {})[0]
  const apollo = useApolloClient()

  const onNavigateToMyProfile = () => {
    goTo("myProfile").myProfile()
    menuC.close()
  }

  return (
    <div
      className={classNames(
        css.customMenu,
        m.isClosing && css.closing,
        m.isClosing && "closing",
        m.isOpening && css.opening,
        m.isOpening && "opening",
        m.isOpen ? css.open : css.closed
      )}
      onClick={(e) => {
        if (e.currentTarget !== e.target) {
          return
        }
        menuC.close()
      }}
    >
      <div ref={divRef} className={css.menuContent} {...dataCy("right-menu")}>
        {isDesktop && (
          <div
            style={{
              paddingInlineStart: 170,
            }}
          >
            <PuxButtons>
              <PuxTypography variant="h3">{t("General.Menu")}</PuxTypography>
              <PuxButton
                color={"light"}
                onClick={() => menuC.close()}
                style={{
                  paddingInline: 50,
                }}
              >
                {t("General.Close")}
                <PuxIcon icon={close} size={"small"}></PuxIcon>
              </PuxButton>
            </PuxButtons>
          </div>
        )}
        {!isDesktop && (
          <>
            <div className={classNames(css.userMenuProfile, css.menuPadding)}>
              <PuxLabel>
                {token.given_name} {token.family_name}
              </PuxLabel>

              <div style={{ margin: 20 }}>
                <AvatarInitials
                  theme={"dark"}
                  givenName={token.given_name}
                  familyName={token.family_name}
                ></AvatarInitials>
              </div>

              <PuxButtons className={classNames(css.profileButton)}>
                <PuxButton
                  onClick={(e) => {
                    onNavigateToMyProfile()
                  }}
                  /*href={accountUrl}*/
                  fill="outline"
                  size="default"
                  class={css.showAccountButton}
                  style={{
                    "--border-color": "#888888",
                    "--color": "#FFFFFF",
                    "--color-activated": "#FFFFFF",
                    "--background-activated": "#00000000",
                  }}
                >
                  {t("General.MyProfile")}
                </PuxButton>
              </PuxButtons>
            </div>
          </>
        )}
        <PuxList>
          {!!firstPurchase && (
            <PuxItem
              button
              style={{ "--background": "unset" }}
              onClick={async () => {
                goTo("right-menu", null, {
                  paymentID: firstPurchase.paymentID,
                }).paymentCallbackVipps()
                menuC.close()
              }}
            >
              <PuxIcon
                slot="start"
                icon={warning}
                style={{ transform: "translateX(-6px)" }}
              />
              {t("Routes.PaymentCallback")}
              <ApprovalStatusFlag
                status="red"
                slot="end"
                style={{
                  transform: "translateX(8px)",
                }}
              />
            </PuxItem>
          )}
          {featureFlags.showMyReceipts && (
            <PuxItem
              button
              style={{ "--background": "unset" }}
              onClick={async () => {
                goTo("right-menu").myReceipts()
                menuC.close()
              }}
            >
              <PuxIcon
                slot="start"
                icon={shoppingCart}
                style={{ transform: "translateX(-8px)" }}
              />
              {t("Routes.Receipts")}
              <MyReceiptsUnreadBadge
                slot="end"
                color="tertiary"
                style={{
                  transform: "translateX(8px)",
                }}
              />
            </PuxItem>
          )}

          <PuxItem
            button
            style={{ "--background": "unset" }}
            onClick={async () => {
              goTo("right-menu").subscriptionActivity()
              menuC.close()
            }}
          >
            <PuxIcon
              slot="start"
              icon={activityLog}
              style={{ transform: "translateX(-5px) translateY(-3px)" }}
            />
            {t("Routes.SubscriptionActivity")}
            <OrdersUnreadBadge
              slot="end"
              color="tertiary"
              style={{
                transform: "translateX(8px)",
              }}
            />
          </PuxItem>

          {enabledMenuConsentItem && (
            <>
              <PuxItem
                button
                style={{ "--background": "unset" }}
                onClick={async () => {
                  goTo("right-menu").menuSettings()
                  menuC.close()
                }}
              >
                <PuxIcon
                  slot="start"
                  icon={profile}
                  style={{ transform: "translateX(-8px)" }}
                />
                {t("Innstillinger")}
              </PuxItem>
            </>
          )}

          {!!approver && (
            <PuxItem
              button
              style={{ "--background": "unset" }}
              onClick={() => {
                goTo("right-menu").subscriptionRequests()
                menuC.close()
              }}
            >
              <PuxIcon
                icon={request}
                slot="start"
                style={{ transform: "translateX(-4px) translateY(-3px)" }}
              />
              {t("Routes.SubscriptionRequests")}
              <ApprovalBadgeCount refreshBadgeCount={updateBadgeCount} />
            </PuxItem>
          )}
          {!isDesktop && (
            <PuxItem
              button
              style={{ "--background": "unset" }}
              onClick={() => {
                goTo("right-menu").settings()
                menuC.close()
              }}
            >
              <PuxIcon
                icon={profile}
                slot="start"
                style={{ transform: "translateX(-5px) translateY(-4px)" }}
              />
              {t("Routes.Settings")}
            </PuxItem>
          )}
        </PuxList>
        {!isDesktop && keycloak.authenticated && (
          <>
            <PuxList>
              <hr
                style={{
                  opacity: 0.2,
                  marginInline: 0,
                  marginInlineEnd: 0,
                  marginBlock: 12,
                }}
              />
              {!!chatbotFeatureFlag && (
                <PuxItem
                  detailIcon={""}
                  detailFill="transparent"
                  style={{
                    "--background": "unset",
                    paddingInlineStart: 60,
                    fontWeight: 300,
                  }}
                  button
                  onClick={() => {
                    zE("messenger", "show")
                    zE("messenger", "open")
                  }}
                >
                  {" "}
                  <PuxIcon
                    icon={chat}
                    className={css.chatIcon}
                    style={{ marginRight: 10 }}
                  />
                  Chat med oss{" "}
                </PuxItem>
              )}
              <PuxItem
                detailIcon={""}
                detailFill="transparent"
                style={{
                  "--background": "unset",
                  paddingInlineStart: 60,
                  fontWeight: 300,
                }}
                href="https://support.phonero.no/hc/no/"
                button
              >
                Hjelpesenter{" "}
                <PuxIcon icon={externalLink} className={css.externalIcon} />
              </PuxItem>
              <PuxItem
                detailIcon={""}
                detailFill="transparent"
                style={{
                  "--background": "unset",
                  paddingInlineStart: 60,
                  fontWeight: 300,
                }}
                href="https://www.phonero.no/kontakt-oss"
                button
              >
                Kontakt oss{" "}
                <PuxIcon icon={externalLink} className={css.externalIcon} />
              </PuxItem>
              <PuxItem
                detailIcon={""}
                href="https://www.phonero.no/informasjon/personvern"
                detailFill="transparent"
                style={{
                  "--background": "unset",
                  paddingInlineStart: 60,
                  fontWeight: 300,
                }}
                onClick={() => {}}
                button
              >
                Personvern{" "}
                <PuxIcon icon={externalLink} className={css.externalIcon} />
              </PuxItem>
            </PuxList>
            <hr
              style={{
                opacity: 0.2,
                marginInline: 0,
                marginInlineEnd: 0,
                marginBlock: 12,
              }}
            />
            <PuxList>
              <PuxItem
                detailIcon={""}
                detailFill="transparent"
                style={{ "--background": "unset" }}
                onClick={async () => {
                  const _res3 = await logout()
                  menuC.close()
                }}
                button
              >
                <PuxIcon icon={logOut} slot="start" />
                {t("General.Logout")}
                <div className="underlineColored" />
              </PuxItem>
            </PuxList>
          </>
        )}

        {isDevEnv && (
          <React.Suspense fallback={"..."}>
            {t("General.DeveloperMenu")}
            <DevMenu />
          </React.Suspense>
        )}
        <Version />
      </div>
    </div>
  )
}

declare const zE: (method: string, action: string) => void

const Version = () => {
  const appInfo = useAppInfo()
  return (
    <div
      style={{
        textAlign: "center",
        marginBlockStart: "auto",
        paddingBottom: "env(safe-area-inset-bottom)",
      }}
      className={createTypographyClasses("body2")}
    >
      <BuildInfoButton color="light">
        {appInfo.versionNumber || buildInfo.tag}
        {appInfo.versionCode ? ` (${appInfo.versionCode})` : ""}
      </BuildInfoButton>
    </div>
  )
}
