import { FC } from "react"
import cx from "classnames"
import css from "./GeneralError.module.scss"
import { PuxSheet, PuxText } from "@phonero/pux-react"

interface GeneralErrorProps {
  title: string
  messageBody: React.ReactNode
  messageFooter: React.ReactNode
}

/***
 * This component is used when the user is logged in but we cannot find a subscription for them.
 * This is reflected in the TokenData, where the PersonId is not present.
 */
export const GeneralError = ({
  title,
  messageBody,
  messageFooter,
}: GeneralErrorProps) => {
  return (
    <div className={cx(css.pageContainer)}>
      <PuxSheet
        color="light"
        className={cx(css.sheetContainer, css.flexColumn)}
      >
        <PuxText style={{ fontSize: "2.4rem", fontWeight: 900 }}>
          {title}
        </PuxText>
        <PuxText className={cx(css.messageBody, css.fontWeightStandard)}>
          {messageBody}
        </PuxText>
        <PuxText className={cx(css.messageBody, css.fontWeightMedium)}>
          {messageFooter}
        </PuxText>
      </PuxSheet>
    </div>
  )
}
