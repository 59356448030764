import { PuxAvatar } from "@phonero/pux-react"
import classNames from "classnames"
import React, { FC } from "react"
import css from "./AvatarInitials.module.scss"

export const AvatarInitials: FC<
  {
    theme?: string
    givenName?: string
    familyName?: string
    initialsStyles?: React.CSSProperties
    defaultCursor?: Boolean
    onAvatarClick?: JSX.IntrinsicElements["button"]["onClick"]
  } & Parameters<typeof PuxAvatar>[0]
> = ({
  givenName,
  familyName,
  className,
  initialsStyles,
  defaultCursor,
  onAvatarClick,
  theme,
  ...rest
}) => {
  return (
    <PuxAvatar
      {...rest}
      className={classNames(
        className,
        css.avatar,
        css.center,
        theme === "light" ? css.mediumBackground : css.darkBackground,
        rest.class,
        (rest as any).className
      )}
    >
      <button
        style={defaultCursor && { cursor: "default" }}
        className={classNames("pux-focusable", css.avatarButton)}
        onClick={onAvatarClick}
      >
        <div
          className={classNames(
            css.initials,
            css.mediumColor,
            theme === "light" ? css.darkColor : css.mediumColor
          )}
          style={initialsStyles}
        >
          {!!givenName && !!familyName
            ? givenName.charAt(0).toUpperCase() +
              familyName.charAt(0).toUpperCase()
            : ""}
          {!!givenName && !familyName ? givenName.substring(0, 2) : ""}
          {!!familyName && !givenName ? familyName.substring(0, 2) : ""}
        </div>
      </button>
    </PuxAvatar>
  )
}
