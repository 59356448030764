import { GeneralError } from "../General"

/***
 * This component is used when the user is logged in but we cannot find a subscription for them.
 * This is reflected in the TokenData, where the PersonId is not present.
 */
export const MissingPerson = () => {
  const title = "Beklager, vi har problemer med å bekrefte abonnementet ditt"
  const messageBody =
    "Vi ser at du har logget inn, men vi kan ikke bekrefte at abonnementet ditt er aktivt akkurat nå. Dette kan skyldes at du ikke har et aktivt abonnement hos oss eller en midlertidig teknisk feil."
  const messageFooter =
    "Hvis du har et aktivt abonnement hos oss, kan du prøve å logge inn igjen om noen minutter."

  return <GeneralError title={title} messageBody={messageBody} messageFooter={messageFooter} />
}
