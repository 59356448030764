import { KeycloakLogoutOptions } from "keycloak-js"
import { useKeycloak } from "./useKeycloak"
import { useApolloClient } from "@apollo/client"

/**
 * This provide a common logout function that can be reused wherever we are logging out...
 * */
export const useLogout = () => {
  const { initialized, keycloak } = useKeycloak()
  const client = useApolloClient()

  /**
   * Logout if authenticated.
   * Clear apollo cache and logout user.
   */
  async function logout(redirectUri?: string) {
    // Clear Apollo cache
    // Do not await for this to finish as this takes some time.
    if (!!client) {
      client.resetStore()
      // client.clearStore()
    }

    localStorage.clear()

    if (!redirectUri) {
      redirectUri = window.location.origin
    }

    // Do we need special handling for offline_access?

    const authenticated = initialized && keycloak.authenticated
    if (authenticated) {
      const logoutOptions: KeycloakLogoutOptions = {
        redirectUri: redirectUri,
      }
      await keycloak.logout(logoutOptions)
    }
  }

  return {
    /** Common logout for Phonero */
    logout,
  }
}

export default useLogout
