import React from "react"
import { PuxItem } from "@phonero/pux-react"
import { dataCy } from "../util"
import classNames from "classnames"
import { closeAccordion } from "@phonero/pux-icons/icons"
import { Required } from "simplytyped"
import { IProduct, IService } from "@phonero/common-graphql/types"

export type PuxItemProps = ConstructorParameters<typeof PuxItem>[0]

export type Product = Required<Partial<IProduct & IService>, "id">

export const ProductItem = ({
  product,
  isSelected,
  xs,
  accordian: Accordian,
  Component,
  onSelectProduct,
  setSelected,
  widestAvailablePackageSize,
  ...puxItemProps
}: {
  // TODO: fix these types
  product: any
  isSelected: any
  xs: any
  accordian?: any
  Component: any
  onSelectProduct?: any
  setSelected: React.Dispatch<React.SetStateAction<string | null>>
  widestAvailablePackageSize: any
} & ConstructorParameters<typeof PuxItem>[0]) => {
  const ref = React.useRef<HTMLDivElement>(null)
  
  if (!product.id) {
    return null
  }

  return (
    <React.Fragment key={product.id}>
      <PuxItem
        {...dataCy("add-data-" + product.id)}
        {...(xs &&
          !Accordian && {
            detail: false,
          })}
        key={product.id}
        {...(!!onSelectProduct && {
          button: true,
          onClick: () => onSelectProduct(product),
          ...(!!Accordian && {
            onClick: () =>
              product.id &&
              setSelected((s) => {
                if (s === product.id) {
                  return null
                }
                requestAnimationFrame(() => {
                  setTimeout(() => {
                    const element = ref.current
                    if (element) {
                      element.scrollIntoView({
                        block: "end",
                        behavior: "smooth",
                      })
                    }
                  }, 150) // Må vente til accordion åpner seg for at den skal scrolle riktig..
                })
                return product.id
              }),

            detailIcon: isSelected
              ? iconAccordianActive
              : iconAccordianInactive,
          }),
        })}
        {...(isSelected && { color: "primary" })}
        style={{
          marginBottom: 2,
          ...(xs && {
            /* TODO: legg det her inn i pux */ "--padding-start": "4px",
            "--padding-end": "10px",
          }),
        }}
        {...(puxItemProps as any)}
      >
        <Component
          {...product}
          widestAvailablePackageSize={widestAvailablePackageSize}
          active={isSelected}
          {...(isSelected && { selected: true, className: "active" })}
          color={puxItemProps.color}
          {...(Accordian && { hasDetailIcon: true })}
        />
      </PuxItem>
      {!!Accordian && (
        <div
          ref={ref}
          {...(isSelected && {
            style: { maxHeight: ref?.current?.scrollHeight || 10 },
          })}
          className={classNames("accordian-expand", isSelected && "active")}
        >
          {typeof Accordian === "function" ? (
            <Accordian {...product} />
          ) : (
            Accordian
          )}
        </div>
      )}
    </React.Fragment>
  )
}

const iconAccordianActive = closeAccordion.replace(
  "<svg ",
  '<svg style="transition: all 1s; transform: rotate(180deg);" '
)
const iconAccordianInactive = closeAccordion.replace(
  "<svg ",
  '<svg style="transition: all 1s;" '
)
