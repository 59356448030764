import { KeycloakLoginOptions, KeycloakLogoutOptions } from "keycloak-js"

import { isCapacitor } from "./platform"
import { useKeycloak } from "./useKeycloak"
import { useTokenData } from "./useTokenData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

/** Authentication levels to comply with EKOM */
export enum AuthenticationLevel {
  /*** User name and password and with SMS authentication */
  Standard = 1,

  /*** BankId by biomeri */
  Enhanced = 2,
}

export const useAuthentication = (
  requiredAuthenticationLevel: AuthenticationLevel
) => {
  const { initialized, keycloak } = useKeycloak()
  const tokenData = useTokenData()

  /** Check feature for BankId authentication
   *
   * Enabled:
   * BankId must be enabled in KeyCloak
   * Check that the user is authenticated at the AuthenticationLevel.
   * Supports forced login with BankId
   *
   * Disabled:
   * Only check if user is Authenticated and discard any Authentication levels.
   * Suppresses forced login with BankId
   */
  const isFeatureBankIdEnabled = useFeatureIsOn("selfservice-bankid")

  // Tried to use ACR to determine the level of authentication
  // Implemented stepup in keycloak, but we had to step up through the various authentication levels.
  // Decided that we are better of with a custom login screen per application.
  // const tokenAcr = tokenData?.acr

  const tokenImpersonated = tokenData?.impersonator
  const tokenIdentityProvider = tokenData.identity_provider
  const personId = tokenData?.personId

  /**
   * Basic login with Keycloak
   */
  const loginWithKeycloakParams: KeycloakLoginOptions = {
    scope: isCapacitor ? "offline_access" : "",
  }

  /**
   * Force BankId login with biometric
   * */
  const loginWithBankIdParams: KeycloakLoginOptions = {
    scope: isCapacitor ? "offline_access" : "",
    idpHint: "bankid-oidc",
    acrValues: "urn:bankid:bis",
    // loginHint: "BIS",
  }

  /**
   * Check if the user is authenticate at the given level
   */
  function getAuthenticatedAtLevel(level: AuthenticationLevel): boolean {
    if (!initialized) return false
    if (!keycloak.authenticated) return false

    if (level === AuthenticationLevel.Standard) return true
    if (!isFeatureBankIdEnabled) return true

    // Allow if token in impersonated
    if (tokenImpersonated !== undefined) return true

    // We know that all login with BankId has the Authentication Context Reference (ACR) level 3
    // According to EKOM loven, this is sufficient for all levels of authentication => granth access.
    if (tokenIdentityProvider === "bankid-oidc") return true

    return false
  }

  /** Get the current level of authentication */
  function getCurrentAuthenticatedLevel(): AuthenticationLevel | undefined {
    if (isFeatureBankIdEnabled) return AuthenticationLevel.Standard
    if (getAuthenticatedAtLevel(AuthenticationLevel.Enhanced))
      return AuthenticationLevel.Enhanced
    if (getAuthenticatedAtLevel(AuthenticationLevel.Standard))
      return AuthenticationLevel.Standard
    return undefined
  }

  /**
   * Logout if authenticated
   * To force login at a higher level of authentication it is necessary to logout first.
   * Otherwise Keycloak will just return the current token.
   */
  async function logoutIfAuthenticated() {
    const authenticated = initialized && keycloak.authenticated
    if (authenticated) {
      const logoutOptions: KeycloakLogoutOptions = {
        redirectUri: window.location.origin,
      }
      await keycloak.logout(logoutOptions)
    }
  }

  /**
   * This triggers the normal login flow in KeyCloak
   * * For DinBedrift the login flow requires BankId identification and will result in AuthenticationLevel Enhanced
   * - For DittPhonero the login flow promote BankId but the user can choose to login with username and password
   * - -  So the result can be either AuthenticationLevel.Standard or AuthenticationLevel.Enhanced
   *
   * With Single Sign On (SSO) the user can have invalid authentication level, so we need to check the level before login and trigger login if needed.
   * */
  async function loginWithKeycloak(authenticationLevel: AuthenticationLevel) {
    if (getAuthenticatedAtLevel(authenticationLevel)) return

    await logoutIfAuthenticated()

    const loginOptions = loginWithKeycloakParams
    await keycloak.login(loginOptions)
  }

  /** Force Authentication with BankId to trigger AuthenticationLevel Enhanced */
  async function loginWithBankId() {
    if (!isFeatureBankIdEnabled) throw new Error("BankId is not enabled")

    if (getAuthenticatedAtLevel(AuthenticationLevel.Enhanced)) return

    // Looks like we are logged out by default when accessing BankId through KeyCloak
    // logoutIfAuthenticated()

    const loginOptions = loginWithBankIdParams
    await keycloak.login(loginOptions)
  }

  const currentAuthenticationLevel = getCurrentAuthenticatedLevel()
  const isAuthenticatedAtLevel = getAuthenticatedAtLevel(
    requiredAuthenticationLevel
  )

  return {
    /** True if the keycloak connection is initialized */
    initialized,

    /** True if authenticated (does not check against Authentication Level) */
    isAuthenticatedPerson: !!keycloak.authenticated && !!personId,

    /** True if authenticated (does not check against Authentication Level) */
    isAuthenticated: !!keycloak.authenticated,

    /** True if authenticated according to the specified Authentication Level */
    isAuthenticatedAtLevel,

    /** Current Authentication Level */
    currentAuthenticationLevel,

    /** Trigger standard login flow in KeyCloak. */
    loginWithKeycloak,

    /** Trigger BankId login flow in KeyCloak */
    loginWithBankId,
  }
}

export default useAuthentication
