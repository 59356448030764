import {
  PuxIcon,
  PuxSkeletonText,
  PuxText,
  useTranslation,
} from "@phonero/pux-react"
import { payPerMb, infinity } from "@phonero/pux-icons/icons"
import css from "./DataPackage.module.scss"

import {
  DataPackage as DataPackageType,
  DataPackageProduct,
  ExtraDataPackageProduct,
  DataPackageSizeMetadataFlag,
} from "@phonero/common-graphql/types"
import classNames from "classnames"
import Price from "../Other/Price"
import { IExtraDataProduct } from "./DataPackageModel"
import { normalizeSizeAndUnit } from "../util/dataPackageSizeHelper"
import { PackageSizeFlag } from "./PackageSizeFlag"

export type DataPackageProps = Partial<DataPackageType> &
  Partial<DataPackageProduct> &
  Partial<IExtraDataProduct> &
  Partial<ExtraDataPackageProduct> & {
    priceDiff?: number
    textProps?: Parameters<typeof PuxText>[0]
    iconProps?: Parameters<typeof PuxIcon>[0]
    subscriptionName: string
    name: string
    active?: boolean
    activeDataPackagePrice?: number
    hasIconStart?: boolean
    hasIconEnd?: boolean
    color?: string
    widestAvailablePackageSize: number
    priceSuffix?: string | undefined
    packageSizeFlags?: (DataPackageSizeMetadataFlag | PackageSizeFlag)[] //Did we need two types of flags for the same thing? cleanup!
  }

export const DataPackage = ({
  price,
  priceDiff,
  isPriceFrom,
  priceSuffix,
  /* The name of the products should all be the same, ignoring the name that the api returns */
  subscriptionName,
  name,
  packageSize,
  active,
  size,
  sizeType,
  textProps,
  iconProps,
  hasIconStart,
  hasIconEnd,
  widestAvailablePackageSize,
  color,
  packageSizeFlags,
}: DataPackageProps) => {
  const { t } = useTranslation()

  subscriptionName = subscriptionName ?? name
  if (!subscriptionName) {
    return <PuxSkeletonText animated />
  }

  const packageGB = size ?? packageSize
  const { size: normalizedSize, sizeUnit: normalizedUnit } =
    normalizeSizeAndUnit(packageGB || 0, sizeType ?? "MB")

  const isPayPerMb =
    packageSizeFlags?.includes(DataPackageSizeMetadataFlag.Payperbyte) ||
    packageSizeFlags?.includes(PackageSizeFlag.PayPerByte)
  const isUnlimited =
    packageSizeFlags?.includes(DataPackageSizeMetadataFlag.Unlimited) ||
    packageSizeFlags?.includes(PackageSizeFlag.Unlimited)

  return (
    <div
      className={classNames(css.container, {
        [css.hasDetailIconEnd]: hasIconEnd,
        [css.hasDetailIconStart]: hasIconStart,
      })}
    >
      <div
        slot="start"
        className={classNames(css.wrapper, {
          [css.hasDetailIconEnd]: hasIconEnd,
        })}
      >
        {isUnlimited ? (
          <div
            className={classNames(css.sizeTextContainer, {
              [css.sizeWidth1]: widestAvailablePackageSize === 1,
              [css.sizeWidth2]: widestAvailablePackageSize === 2,
              [css.sizeWidth3]: widestAvailablePackageSize === 3,
              [css.sizeWidth4]: widestAvailablePackageSize === 4,
            })}
          >
            <PuxIcon
              className={classNames(css.iconContainer, {
                [css.highlight]: color !== "dark" && !active,
              })}
              size="small"
              {...iconProps}
              icon={infinity}
            />
          </div>
        ) : isPayPerMb ? (
          <div
            className={classNames(css.sizeTextContainer, {
              [css.sizeWidth1]: widestAvailablePackageSize === 1,
              [css.sizeWidth2]: widestAvailablePackageSize === 2,
              [css.sizeWidth3]: widestAvailablePackageSize === 3,
              [css.sizeWidth4]: widestAvailablePackageSize === 4,
            })}
          >
            <PuxIcon
              className={classNames(css.iconContainer, {
                [css.highlight]: color !== "dark" && !active,
              })}
              {...iconProps}
              icon={payPerMb}
            />
          </div>
        ) : (
          <span
            slot="start"
            className={classNames(css.sizeTextContainer, {
              [css.sizeWidth1]: widestAvailablePackageSize === 1,
              [css.sizeWidth2]: widestAvailablePackageSize === 2,
              [css.sizeWidth3]: widestAvailablePackageSize === 3,
              [css.sizeWidth4]: widestAvailablePackageSize === 4,
              [css.highlight]: color !== "dark" && !active,
            })}
            {...textProps}
          >
            {normalizedSize}
          </span>
        )}
        <div
          className={classNames(css.gbText, {
            [css.payPerMbText]: isPayPerMb,
          })}
        >
          {isPayPerMb
            ? t("ExtraData.PayPerUsage")
            : t("ExtraData.SizeType", {
                context: normalizedUnit,
                count: normalizedSize,
              })}
        </div>
      </div>
      {color === "dark" ? (
        <>
          <div className={css.productName}>
            <strong>{subscriptionName}</strong>
          </div>
        </>
      ) : (
        <>
          <div className={css.productNameChangeTo}>
            <strong>{subscriptionName}</strong>
          </div>
        </>
      )}

      <div
        slot="end"
        className={classNames(css.end, hasIconEnd && css.hasDetailIconEnd)}
      >
        <span>
          {!!price && price > 0 && (
            <Price
              value={price}
              priceDiff={priceDiff}
              {...(!!isPriceFrom && { prefix: "priceFrom" })}
              {...(!!priceSuffix && { suffix: ["perMonth"] })}
            />
          )}
        </span>
      </div>
    </div>
  )
}
