
/*

The semver library does not work on android so using this instead.
It ignores suffix like -dev -test etc.

Example usage:
console.log(compareVersions('1.0.0', '1.0.0-dev')); // 0
console.log(compareVersions('1.0.0-dev', '1.0.0-test')); // 0
console.log(compareVersions('1.0.0-test', '1.0.0')); // 0
console.log(compareVersions('1.0.1-test', '1.0.0')); // 1
console.log(compareVersions('1.0.0-test', '1.0.1')); // -1
console.log(compareVersions('1.0.1', '1.0.0')); // 1
*/


export function compareVersions(v1: string, v2: string) {
  const semverRegex = /^(\d+)\.(\d+)\.(\d+)(?:-([a-zA-Z0-9]+))?$/;

  function parseVersion(version) {
    const match = version.match(semverRegex);
    if (!match) {
      throw new Error(`Invalid version format: ${version}`);
    }
    return {
      major: parseInt(match[1], 10),
      minor: parseInt(match[2], 10),
      patch: parseInt(match[3], 10),
      label: match[4] || ''
    };
  }

/*  function compareLabels(label1, label2) {
    const order = ['dev', 'test', ''];
    const index1 = order.indexOf(label1);
    const index2 = order.indexOf(label2);
    return index1 - index2;
  }*/

  const v1Parsed = parseVersion(v1);
  const v2Parsed = parseVersion(v2);

  if (v1Parsed.major !== v2Parsed.major) {
    return v1Parsed.major - v2Parsed.major;
  }
  if (v1Parsed.minor !== v2Parsed.minor) {
    return v1Parsed.minor - v2Parsed.minor;
  }
  if (v1Parsed.patch !== v2Parsed.patch) {
    return v1Parsed.patch - v2Parsed.patch;
  }
  //return compareLabels(v1Parsed.label, v2Parsed.label);
  return 0;
}
