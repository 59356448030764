import { GeneralError } from "../General"
import { PuxTypography } from "@phonero/pux-react"

/***
 * This component is used when the user is logged in but we cannot find a subscription for them.
 * This is reflected in the TokenData, where the PersonId is not present.
 */
export const AppUpdateRequired = () => {
  const title = "Oppdater appen"
  const messageBody =
    "For å logge inn, må du ha siste versjon. \n\n Last ned fra App Store eller Google Play."
  const messageFooter = (
    <>
      <a href={"https://dp.phonero.no"}>
        <PuxTypography variant={"label"} >Last ned siste versjon her</PuxTypography> <br/>
      </a>
    </>
  )

  return <GeneralError title={title} messageBody={messageBody} messageFooter={messageFooter} />
}
