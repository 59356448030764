import React from "react"
import { PuxItem, PuxText } from "@phonero/pux-react"
import { PropsWithChildren } from "react"
import { Maybe } from "@phonero/common-graphql/types"
import { useAppTranslations } from "./util"
import Price from "./Other/Price"

interface ProductDialogDetailsProps {
  size?: number
  sizeType?: string
  name?: string
  price?: string | Maybe<number>
}

export const ProductDialogDetails = ({
  children,
  size,
  sizeType,
  name,
  price,
}: PropsWithChildren<ProductDialogDetailsProps>) => {
  const { t } = useAppTranslations()
  return (
    <>
      <PuxItem color="dark">
        {size && (
          <PuxText
            slot="start"
            style={{
              fontSize: "2.8rem",
              fontWeight: 700,
              minWidth: "3rem",
              textAlign: "center",
              marginInlineEnd: "1rem",
            }}
          >
            {size}
          </PuxText>
        )}
        {sizeType && (
          <PuxText>
            {t("ExtraData.SizeType", { context: sizeType, count: size })}
          </PuxText>
        )}
        {name && (
          <PuxText
            style={{ margin: "auto", paddingRight: "7rem", fontWeight: 700 }}
          >
            {name}
          </PuxText>
        )}
        {price && (
          <PuxText style={{ fontWeight: 700 }} slot="end">
            <Price value={price}></Price>
          </PuxText>
        )}
      </PuxItem>
      <PuxItem>
        <PuxText style={{ display: "block", width: "100%" }}>
          {children}
        </PuxText>
      </PuxItem>
    </>
  )
}
