import { useKeycloak as useK } from "@react-keycloak/web"

import { ArgsAsTuple } from "simplytyped"
import { useAppConstants } from "../AppConstants"

let mock: any = undefined

type UseK = typeof useK
type KeyCloak = ReturnType<UseK>["keycloak"]

/** Closure wrapping the keycloak-instance, returning a logout-function
 *
 * Note that this is not the same as simply calling `keycloak.logout()`.
 */
export const logoutClosure =
  (keycloak: KeyCloak) =>
  async (options?: ArgsAsTuple<KeyCloak["logout"]>[0]) => {
    // NOTE: Ordering matters!
    // Clears the users cookies and removes the session from keycloak.
    const logoutPromise = await keycloak.logout({
      redirectUri: await keycloak.createLoginUrl(),
      ...options,
    })
    // Removes the token from the users device. This _must_ be called when logging out in the
    const result = await logoutPromise
    return result
  }

export function useKeycloak(): ReturnType<typeof useK> & {
  logout: KeyCloak["logout"]
} {
  const { authProps, graphqlEndpoint } = useAppConstants()

  if (!authProps.enableMock) {
    const k = useK()
    return { ...k, logout: logoutClosure(k.keycloak) as any }
  }

  // The code below should have been removed during build, and is only available in dev.

  const _mock =
    localStorage.getItem("__cypress_mock") ||
    process.env.REACT_APP_MOCK_KEYCLOAK
  if (_mock) {
    if (_mock.startsWith("{")) {
      mock = JSON.parse(_mock)
      if (!mock) {
        console.debug("mock enabled")
      }
    } else {
      switch (_mock.toLowerCase()) {
        case "1":
        case "true":
        case "yes":
          mock = true
          if (!mock) {
            console.debug("mock enabled")
          }
          break

        default:
          if (mock) {
            console.debug("mock disabled")
          }
          mock = false
          break
      }
    }
  }
  if (!mock) {
    const k = useK()
    return { ...k, logout: logoutClosure(k.keycloak) as any }
  }
  const date = new Date()
  const nowish = Math.floor(date.getTime() / 1000)
  const day = 60 * 60 * 24
  const blackHole =
    (s: string) =>
    (...args: Array<any>) => {
      console.debug(`mocked function (${s}) called`, args)
    }
  const users = {
    janne: {
      sub: "janne",
      name: "Janne Karlsen",
      family_name: "Karlsen",
      given_name: "Janne",
      preferred_username: "48012345",
      "http://schemas.phonero.no/claims/subscription": ["janne"],
    },
    jan: {
      sub: "jan",
      name: "Jan Ottesen",
      family_name: "Ottesen",
      given_name: "Jan",
      preferred_username: "48011223",
      "http://schemas.phonero.no/claims/subscription": ["jan"],
    },
  }
  return {
    logout: blackHole("register") as any,
    initialized: true,
    keycloak: {
      register: blackHole("register"),
      login: blackHole("register"),
      logout: blackHole("register"),
      createAccountUrl: () => "http://example.com",
      authenticated: true,
      idTokenParsed: {
        exp: nowish + 90 * day,
        iat: nowish,
        auth_time: nowish,
        jti: "c99363ed-cbc8-4c22-beb9-2c42f90a0b2e",
        iss: `${authProps.options.url}/realms/${authProps.options.realm}`,
        aud: "ditt-test.phonero.no",
        sub: "4991557a-e521-44f1-805b-3bcd510dd7f4",
        typ: "ID",
        azp: "ditt-test.phonero.no",
        nonce: "19fb514e-6919-46a5-94e0-1e37c1a0a818",
        session_state: "ac5ef0bb-1fc4-45be-9025-8d8adc32bfa3",
        acr: "1",
        "http://schemas.phonero.no/claims/approver": [
          "0011721103",
          "0011721105",
          "0011642736",
          "0011298173",
          "0011739901",
        ],
        Demo: [],
        email_verified: false,
        graphqlEndpoint: graphqlEndpoint,
        preferred_username: "48895816",
        "http://schemas.phonero.no/claims/personid": ["54134"],
        locale: "no",
        "http://schemas.phonero.no/claims/customerid": ["0011721103"],
        "http://schemas.phonero.no/claims/subscription": ["31813661"],
        given_name: "Test",
        name: "Test Bruker",
        family_name: "Bruker",
        email: "ittester2@phonero.no",
        ...(users.janne as any),
        ...(authProps.mock.user && users[authProps.mock.user]),
        ...authProps.mock,
      },
    } as any,
  }
}
