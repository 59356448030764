import { ReactKeycloakProvider as KeycloakProvider } from "@react-keycloak/web"
import Keycloak, {
  KeycloakInitOptions,
  KeycloakLoginOptions,
} from "keycloak-js"
import { AuthClientEvent, AuthClientError } from "@react-keycloak/core"
import { useEffect } from "react"
// TODO: for this we can use cordova-checks or env.variables

import { isCapacitor, isHybrid } from "../util/platform"
import { appStorage } from "../util/storage"
import { PropsWithChildren } from "react"
import { useAppConstants } from "../AppConstants"
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react"

export const keycloakLoginOptions: KeycloakLoginOptions = {
  scope: isCapacitor ? "offline_access" : "",
}

export const keycloakState: {
  ready: boolean
  errors: Array<Error | AuthClientError>
  lastState: string
  states: string[]
  getLastErrorMsg: () => string
} = {
  ready: false,
  errors: [],
  lastState: "",
  states: [],
  getLastErrorMsg: () => {
    if (!keycloakState.errors.length) {
      return ""
    }
    const last = keycloakState.errors[keycloakState.errors.length - 1]
    if (typeof last === "object" && "error_description" in last) {
      return [last.error, last.error_description].filter(Boolean).join(": ")
    }
    return last.message || "mangler feilmelding"
  },
}

export const Auth = ({ children }: PropsWithChildren<{}>) => {
  const { authProps, growthBookApi, growthBookClientKey } = useAppConstants()
  const keycloakClient = new Keycloak(authProps.options)

  const growthbook = new GrowthBook({
    disableCache: true,
    apiHost: growthBookApi,
    clientKey: growthBookClientKey,
    enableDevMode: true,
    subscribeToChanges: true,
    trackingCallback: (experiment, result) => {
      // TODO: Use your real analytics tracking system
      console.log("Viewed Experiment", {
        experimentId: experiment.key,
        variationId: result.key,
      })
    },
  })

  useEffect(() => {
    growthbook.init()
  }, [])

  const keycloakInitOptions: KeycloakInitOptions = {
    checkLoginIframe: !isHybrid,
    silentCheckSsoRedirectUri: !isHybrid
      ? window.location.origin + "/silent-check-sso.html"
      : undefined,
    onLoad: !isHybrid ? "check-sso" : undefined,
    responseMode: "query",
    adapter: "default",
    flow: "standard",
    token: localStorage.getItem("token") || undefined,
    refreshToken: localStorage.getItem("refreshToken") || undefined,
    idToken: localStorage.getItem("idToken") || undefined,
  }

  const onTokens: ConstructorParameters<
    typeof KeycloakProvider
  >[0]["onTokens"] = (tokens) => {
    tokens.token && localStorage.setItem("token", tokens.token)
    tokens.refreshToken &&
      localStorage.setItem("refreshToken", tokens.refreshToken)
    tokens.idToken && localStorage.setItem("idToken", tokens.idToken)
  }

  const onEvent: ConstructorParameters<
    typeof KeycloakProvider
  >[0]["onEvent"] = async (event: AuthClientEvent, error?: AuthClientError) => {
    keycloakState.lastState = event
    keycloakState.states.push(event)
    if (error) {
      keycloakState.errors.push(error)
      // if (_IS_DEV && window.Cypress) {
      //   console.error("an error occured that normally would be throws, but since this is running within Cypress, it is ignored", error);
      //   return;
      // }
      throw new Error(error.error_description)
    }

    switch (event) {
      case "onReady":
        try {
          if (
            keycloakClient &&
            keycloakClient.refreshToken &&
            keycloakClient.isTokenExpired()
          ) {
            keycloakClient.updateToken(0)
            keycloakState.ready = true
            // clear any errors
            keycloakState.errors = []
            return
          }
        } catch (error) {
          keycloakState.errors.push(error)
          console.error(error)
        }
        if (!keycloakClient.authenticated) {
          // We do not want to force the user to login on all pages.
          // keycloakClient.login(keycloakLoginOptions)
        }
        break
      case "onAuthSuccess":
      case "onAuthRefreshSuccess":
        break
      case "onAuthRefreshError":
      case "onAuthError":
        // keycloakClient.login()
        break
      case "onAuthLogout":
        localStorage.removeItem("token")
        localStorage.removeItem("idToken")
        localStorage.removeItem("refreshToken")
        const keys = ["idToken", "refreshToken", "token"]
        await Promise.all(
          keys.map((key) => {
            // We clear from both appStorage and localStorage, since appStorage only handles a handful of the keys.
            localStorage.removeItem(key)
            return appStorage.remove({ key })
          })
        )
        break
      case "onTokenExpired":
        break
      case "onInitError":
        keycloakState.errors.push(new Error("failed to initialize keycloak"))
        break
    }
  }

  return (
    <KeycloakProvider
      authClient={keycloakClient}
      initOptions={keycloakInitOptions}
      onEvent={process.env.NODE_ENV === "test" ? () => {} : onEvent}
      onTokens={onTokens}
    >
      <GrowthBookProvider growthbook={growthbook}>
        {children}
      </GrowthBookProvider>
    </KeycloakProvider>
  )
}
