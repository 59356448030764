import React, { FC } from "react"
import {
  useKeycloak,
  dataCy,
  useBreakpointDesktop,
  useTokenData,
  useLogout,
} from "@phonero/common-ux"
import css from "./DesktopHeader.module.scss"
import {
  PuxButtons,
  PuxTitle,
  PuxToolbar,
  PuxLogo,
  usePuxPopover,
} from "@phonero/pux-react"
import { phonero, dittphonero } from "@phonero/pux-logos/logos"
import { MenuProfile } from "./MenuItems/MenuProfile"

import classNames from "classnames"

import { useAppRouterDP } from "../../util/useAppRouterDP"
import { MenuProfileButton } from "@phonero/common-ux"

export const DesktopHeader: FC<any> = () => {
  const { keycloak } = useKeycloak()
  const { goTo } = useAppRouterDP()
  const { given_name, family_name } = useTokenData()
  const isDesktop = useBreakpointDesktop()
  const { logout } = useLogout()

  // FIXME: [PUX-52] Elements presented in PuxPopover does not have provider-context set.
  const [presentPopoverList, dismiss] = usePuxPopover(MenuProfile, {
    given_name,
    family_name,
    keycloak,
    onLogout: async () => {
      return await logout()
    },
    onNavigateToMyProfile: async () => {
      dismiss()
      goTo("myProfile").myProfile()
    },
  } as Parameters<typeof MenuProfile>[0])

  if (!isDesktop) return null
  // Make the whole header clickable, and return the user to the homepage. It is still possible to add buttons on top (e.stopPropagation).
  return (
    <PuxToolbar
      aria-label="homepage"
      onClick={() => goTo("toolbar").home()}
      color="light"
      shadow
      {...dataCy("nav-home")}
      style={{
        zIndex: 20,
        height: 80,
        display: "flex",
        paddingLeft: 10,
        cursor: "pointer",
      }}
      role="button"
    >
      <PuxLogo logo={phonero} slot="start" style={{ width: 120, height: 48 }} />
      <PuxTitle>
        <PuxLogo
          logo={dittphonero}
          style={{
            marginLeft: -30,
            maxWidth: 200,
            height: 48,
            alignSelf: "center",
          }}
        />
      </PuxTitle>
      {keycloak.authenticated && (
        <PuxButtons className={classNames(css.profileButton)} slot="end">
          <MenuProfileButton
            onClick={(event) => {
              event.stopPropagation()
              presentPopoverList({ event: event.nativeEvent })
            }}
          ></MenuProfileButton>
        </PuxButtons>
      )}
    </PuxToolbar>
  )
}
