import { KeycloakTokenParsed } from "keycloak-js"

import { useKeycloak } from "./useKeycloak"

export interface TokenImpersonator {
  id: string
  username: string
}

export interface TokenData extends KeycloakTokenParsed {
  name?: string
  given_name?: string
  family_name?: string
  email?: string
  email_verified?: boolean
  phone_number?: string
  phone_number_verified?: boolean
  preferred_username?: string
  locale?: string
  /** @deprecated, use subscriptionId instead as it is better suited in most cases*/
  subscriptionid?: number // I
  subscriptionId?: string
  graphqlEndpoint?: string
  customerId?: string
  acr?: string
  impersonator?: TokenImpersonator
  has2fa?: boolean
  hasBankIdLink?: boolean
  /** Identity provider used to authenticate the user.
   * Supported providers:
   * * bankid-oidc
   */
  identity_provider?: string // Supported identity providers : "bankid-oidc"
  personId?: string // PersonId is used to identify the user in the system.
}

export function useTokenData(): TokenData {  
  const { keycloak } = useKeycloak()
  const token = keycloak.idTokenParsed
  const subscriptionid =
    token && token["http://schemas.phonero.no/claims/subscription"]
      ? token["http://schemas.phonero.no/claims/subscription"][0]
      : null
  const customerId =
    token && token["http://schemas.phonero.no/claims/customerid"]
      ? token["http://schemas.phonero.no/claims/customerid"][0]
      : null
  const personId =
      token && token["http://schemas.phonero.no/claims/personid"]
        ? token["http://schemas.phonero.no/claims/personid"][0]
        : null
    const has2fa =
    token && token["otp"]
      ? token["otp"]
      : null
  const l = token && token["http://phonero/claims/linked_identity_provider"]
  const hasBankIdLink = l && typeof l === "string" ? l === "bankid-oidc" : (
    l && typeof l === "object" ? l.some(t => t === "bankid-oidc") : null
  )
  return {
    ...keycloak.idTokenParsed,
    subscriptionid,
    ...(!!subscriptionid && {
      subscriptionId: String(subscriptionid),
    }),
    customerId,
    ...(!!customerId && {
      customerId: String(customerId),
    }),
    personId,
    ...(!!personId && {
      personId: String(personId),
    }),
    has2fa: has2fa === 'otp',
    hasBankIdLink: hasBankIdLink
  }
}

function parseJWT(token: string) {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}
/** Returns tokendata either from keycloak or from local storarge
 * This does not validate the token, or wait for keycloak to initialize
 * It can therefore return data directly on load
 * Under normal use, consider retreiving the data fro keycloak directly
 * *//*
export const getTokenData = (): null | TokenData => { // TODO: This is named incorrectly ... should be a hook, is it the same as the one above?
  const { keycloak } = useKeycloak()
  const token = keycloak.token || localStorage.getItem("token")
  if (!token) {
    return null
  }
  const parsed = parseJWT(token) as any as KeycloakTokenParsed

  const subscriptionid =
    parsed && parsed["http://schemas.phonero.no/claims/subscription"]
      ? parsed["http://schemas.phonero.no/claims/subscription"][0]
      : null
  const customerId =
    parsed && parsed["http://schemas.phonero.no/claims/customerid"]
      ? parsed["http://schemas.phonero.no/claims/customerid"][0]
      : null
  const email =
    parsed && parsed["email"]
      ? parsed["email"][0]
      : null
  const phone_number =
    parsed && parsed["phone_number"]
      ? parsed["phone_number"][0]
      : null
  return {
    ...parsed,
    subscriptionid,
    ...(!!subscriptionid && {
      subscriptionId: String(subscriptionid),
    }),
    customerId,
    ...(!!customerId && {
      customerId: String(customerId),
    }),
    email,
    phone_number
  }
}
*/